@font-face {
    font-family: robotoBold;
    font-style: bold;
    font-weight: 700;
    src: url(../fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
    font-family: robotoRegular;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/Roboto-Regular.ttf) format("truetype");
}


@font-face {
    font-family: din;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/din.woff2) format("woff2");
}

@font-face {
    font-family: glyphs;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/glyphs.eot),
        url(../fonts/glyphs.eot?#iefix) format("embedded-opentype"),
        url(../fonts/glyphs.woff2) format("woff2"),
        url(../fonts/glyphs.woff) format("woff"),
        url(../fonts/glyphs.ttf) format("truetype"),
        url(../fonts/glyphs.svg#nike-futura-extra-bold-v200) format("svg");
}

/*

 __   __   ______     ______     __     ______     ______     __         ______     ______
/\ \ / /  /\  __ \   /\  == \   /\ \   /\  __ \   /\  == \   /\ \       /\  ___\   /\  ___\
\ \ \'/   \ \  __ \  \ \  __<   \ \ \  \ \  __ \  \ \  __<   \ \ \____  \ \  __\   \ \___  \
 \ \__|    \ \_\ \_\  \ \_\ \_\  \ \_\  \ \_\ \_\  \ \_____\  \ \_____\  \ \_____\  \/\_____\
  \/_/      \/_/\/_/   \/_/ /_/   \/_/   \/_/\/_/   \/_____/   \/_____/   \/_____/   \/_____/


*/

$brandColor: #ff0000;
$brandColorHover: RGBA(247, 142, 30, 0.74);
$brandContrast: #ffffff;
$lightGrey: #d4d4d4;
/*

 ______     __         ______     ______     ______     __
/\  ___\   /\ \       /\  __ \   /\  == \   /\  __ \   /\ \
\ \ \__ \  \ \ \____  \ \ \/\ \  \ \  __<   \ \  __ \  \ \ \____
 \ \_____\  \ \_____\  \ \_____\  \ \_____\  \ \_\ \_\  \ \_____\
  \/_____/   \/_____/   \/_____/   \/_____/   \/_/\/_/   \/_____/


*/

html,
body {
    //height: 100%;
    color: black;
    scroll-behavior: smooth;
}

body {
    font-family: "Helvetica Neue", Arial;
    background: white;
    margin: 0;
    padding-top: 63px;
}

.greyBorder {
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 11px;
}

.button {
    color: white;
    background: black;
    border-radius: 27px;
    border: 0;
    padding: 12px 23px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    &:hover {
        background: rgba(0, 0, 0, 0.75);
        cursor: pointer;
    }
}

button:disabled,
button[disabled] {
    background: #bfbfbf;
    &:hover { background: #bfbfbf;  }
}

.buttonSecondary {
    color: black;
    background: white;
    border-radius: 27px;
    padding: 12px 23px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border: 1px solid rgb(161, 161, 161);

    &:hover {
        border: 1px solid rgb(218, 218, 218);
    }
}

.brandButton {
    color: transparent;
    background: transparent;
    border-radius: 27px;
    padding: 12px 23px;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;

    &:hover {
        opacity: 0.7;
    }
}

.backLink {
    color: #8f8f8f;
    position: relative;
    left: -8px;
    display: inline-block;
    margin-bottom: 16px;
    cursor: pointer;

    .iconArrow {
        top: -1px;
        color: #959595;
        transform: rotate(-135deg);
        position: relative;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        width: 6px;
        height: 6px;
        border-width: 1px 1px 0 0;
        border-style: solid;
        margin: 10px;

        &:before {
            content: "";
            box-sizing: border-box;
            right: 0;
            top: 0px;
            position: absolute;
            height: 1px;
            box-shadow: inset 0 0 0 32px;
            transform: rotate(-45deg);
            width: 7px;
            transform-origin: right top;
        }
    }
}
// backlink

/* LOGO | set color as font color */
.logo {
    font-family: glyphs;
    color: $brandContrast;
    font-size: 27px;
    font-style: normal;

    &:before {
        content: "";
        // background: url() no-repeat center;
        background-size: contain;
        width: 104px;
        height: 33px;
        display: block;
    }
}

/*

 ______     ______     ______     ______     __  __     ______   ______     __     __   __     ______   ______
/\  == \   /\  == \   /\  ___\   /\  __ \   /\ \/ /    /\  == \ /\  __ \   /\ \   /\ "-.\ \   /\__  _\ /\  ___\
\ \  __<   \ \  __<   \ \  __\   \ \  __ \  \ \  _"-.  \ \  _-/ \ \ \/\ \  \ \ \  \ \ \-.  \  \/_/\ \/ \ \___  \
 \ \_____\  \ \_\ \_\  \ \_____\  \ \_\ \_\  \ \_\ \_\  \ \_\    \ \_____\  \ \_\  \ \_\\"\_\    \ \_\  \/\_____\
  \/_____/   \/_/ /_/   \/_____/   \/_/\/_/   \/_/\/_/   \/_/     \/_____/   \/_/   \/_/ \/_/     \/_/   \/_____/


*/
$for-phone-only: "only screen and (max-width: 599px)";
$for-tablet-portrait-up: "only screen and (min-width: 600px)";
$for-tablet-landscape-up: "only screen and (min-width: 900px)";
$for-desktop-up: "only screen and (min-width: 1200px)";
$for-big-desktop-up: "only screen and (min-width: 1800px)";
/*


 ______   __  __     ______   ______     ______     ______     ______     ______   __  __     __  __
/\__  _\ /\ \_\ \   /\  == \ /\  __ \   /\  ___\   /\  == \   /\  __ \   /\  == \ /\ \_\ \   /\ \_\ \
\/_/\ \/ \ \____ \  \ \  _-/ \ \ \/\ \  \ \ \__ \  \ \  __<   \ \  __ \  \ \  _-/ \ \  __ \  \ \____ \
   \ \_\  \/\_____\  \ \_\    \ \_____\  \ \_____\  \ \_\ \_\  \ \_\ \_\  \ \_\    \ \_\ \_\  \/\_____\
    \/_/   \/_____/   \/_/     \/_____/   \/_____/   \/_/ /_/   \/_/\/_/   \/_/     \/_/\/_/   \/_____/


*/

body {
    font-family: robotoRegular, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

a {
    font-size: 1em;
    text-decoration: none;
}

h1 {
    font-family: din;
    font-size: 57px;
    text-transform: uppercase;
    line-height: 0.82em;
    letter-spacing: -4px;
}

h2,
marquee {
    font-family: robotoRegular;
    font-size: 21px;
}

h3 {
    font-size: 16px;
    line-height: 111.7%;
    color: #000000;
    font-weight: normal;
}

p {
    margin-bottom: 16px;
}

/*

 ______     __  __     ______     __         ______     ______   ______     __   __
/\  ___\   /\ \/ /    /\  ___\   /\ \       /\  ___\   /\__  _\ /\  __ \   /\ "-.\ \
\ \___  \  \ \  _"-.  \ \  __\   \ \ \____  \ \  __\   \/_/\ \/ \ \ \/\ \  \ \ \-.  \
 \/\_____\  \ \_\ \_\  \ \_____\  \ \_____\  \ \_____\    \ \_\  \ \_____\  \ \_\\"\_\
  \/_____/   \/_/\/_/   \/_____/   \/_____/   \/_____/     \/_/   \/_____/   \/_/ \/_/


*/
/*
.skeleton:empty {
position: relative; overflow: hidden;
background: #e4e4e4;
}
.skeleton:empty::after {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1);
}

@keyframes load {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}
*/

/*

 ______     ______     ______     __    __
/\___  \   /\  __ \   /\  __ \   /\ "-./  \
\/_/  /__  \ \ \/\ \  \ \ \/\ \  \ \ \-./\ \
  /\_____\  \ \_____\  \ \_____\  \ \_\ \ \_\
  \/_____/   \/_____/   \/_____/   \/_/  \/_/


*/

.zoomContainer {
    display: none;
}

.productdetail .zoomContainer,
.packagedetail .zoomContainer {
    display: block;
}

/*

 __  __     ______     ______     _____     ______     ______
/\ \_\ \   /\  ___\   /\  __ \   /\  __-.  /\  ___\   /\  == \
\ \  __ \  \ \  __\   \ \  __ \  \ \ \/\ \ \ \  __\   \ \  __<
 \ \_\ \_\  \ \_____\  \ \_\ \_\  \ \____-  \ \_____\  \ \_\ \_\
  \/_/\/_/   \/_____/   \/_/\/_/   \/____/   \/_____/   \/_/ /_/


*/

.header {
    position: fixed;
    z-index: 3;
    top: 0;
    background: transparent;
    color: transparent;
    width: 100%;
    background-color: #333333;
    color: $brandContrast;

    @media #{$for-tablet-landscape-up} {
        height: 63px;
    }

    a {
        color: inherit;
    }
    /* Welcome ---------- */
    .welcomeHeader {
        display: none;
        overflow: hidden;
        position: relative;
        padding-top: 6px;
        height: 57px;

        .marquee {
            position: absolute;
            width: 50%;
            height: 100%;
            margin: 0;
            line-height: 50px;
            text-align: center;
            /* Starting position */
            transform: translateX(200%);
            /* Apply animation to this element */
            animation: example1 20s linear infinite;
        }
        /* Move it (define the animation) */
        @keyframes example1 {
            0% {
                -moz-transform: translateX(200%); /* Firefox bug fix */
                -webkit-transform: translateX(200%); /* Firefox bug fix */
                transform: translateX(200%);
            }

            100% {
                -moz-transform: translateX(-100%); /* Firefox bug fix */
                -webkit-transform: translateX(-100%); /* Firefox bug fix */
                transform: translateX(-100%);
            }
        }

        .logout {
            label {
                cursor: pointer;
            }
            position: absolute;
            right: 0;
            display: block;
            top: 0;
            padding: 22px 20px;
            background: $brandColor;
            cursor: pointer;
        }
    }
    /* Store ---------- */
    .storeHeader {
        /* position: relative; */ /* taking this out in order to make the cart dropdown fullscreen...... */
        display: none;
        background: #333333;
        -ms-grid-columns: 90px 1fr 64px;
        grid-template-columns: 90px 1fr 50px 64px;
        padding: 19px 0;

        @media #{$for-tablet-landscape-up} {
            background: none;
            padding: 0;
            margin-top: 17px;
            -ms-grid-columns: 135px 1fr 1fr 90px 75px;
            grid-template-columns: 135px 1fr 1fr 90px 75px;
        }

        .logo {
            margin: auto;
            font-size: 20px;
            display: inline-block;
            -ms-grid-column: 1;
            text-align: center;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @media #{$for-tablet-landscape-up} {
                font-size: 27px;
            }
        }

        .eventName {
            margin: auto 0;
            max-height: 36px;
            overflow: hidden;
            display: block;
            -ms-grid-column: 2;
        }

        .budgetInfo {
            text-align: right;
            margin: auto 0;
            display: none;
            -ms-grid-column: 3;

            @media #{$for-tablet-landscape-up} {
                &.active {
                    visibility: visible;
                }

                display: block;
                visibility: hidden;
            }
        }

        .cart {
            margin: auto;
            font-family: glyphs;
            color: inherit;
            font-size: 27px;
            // display: none;
            position: relative;
            cursor: pointer;
            max-width: 21px;

            @media #{$for-tablet-landscape-up} {
                display: inline-block;
                text-align: center;
                -ms-grid-column: 4;
            }

            &:before {
                content: "\E60E";
            }
            /* Hide the browser's default radio button */
            input {
                opacity: 0;
                cursor: pointer;
                position: absolute;
            }
        }

        .cartJewel {
            font-family: verdana;
            user-select: none;
            background: #000000;
            font-size: 10px;
            width: 16px;
            height: 16px;
            padding-top: 0px;
            right: -5px;
            top: -2px;
            color: #fff;
            line-height: 14px;
            border-radius: 50%;
            position: absolute;
            display: block;
            text-align: center;
        }

        .logout {
            margin: auto 0;
            display: none;

            @media #{$for-tablet-landscape-up} {
                display: block;
                -ms-grid-column: 5;
            }
        }

        .hamburger {
            font-family: glyphs;
            color: $brandContrast;
            font-size: 22px;
            text-align: center;

            input {
                opacity: 0;
                cursor: pointer;
            }

            &:after {
                content: "\E62C";
                cursor: pointer;
            }

            @media #{$for-tablet-landscape-up} {
                display: none;
            }
        }
    }
    /*  /storeHeader */
    .thankYouHeader {
        display: none;
    }
}
// /header

/*

 ______     ______     ______     ______      __  __     __
/\  ___\   /\  __ \   /\  == \   /\__  _\    /\ \/\ \   /\ \
\ \ \____  \ \  __ \  \ \  __<   \/_/\ \/    \ \ \_\ \  \ \ \
 \ \_____\  \ \_\ \_\  \ \_\ \_\    \ \_\     \ \_____\  \ \_\
  \/_____/   \/_/\/_/   \/_/ /_/     \/_/      \/_____/   \/_/


*/

.cartUI {
    display: block;
    transition: opacity 500ms, visibility 500ms;
    position: absolute;
    background: #e5e5e5;
    padding: 25px;
    color: #333333;
    width: calc(100% - 50px);
    top: 0;
    max-height: 500px;
    overflow-y: auto;

    @media #{$for-tablet-landscape-up} {
        width: 320px;
        right: 35px;
        top: 63px;
    }

    .noItems { margin-bottom: 20px; }
    .checkmark {
        display: inline-block;
        margin-right: 11px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        position: relative;
        background-color: #56c568;
    }
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "\E611";
        color: white;
        font-family: glyphs;
        position: absolute;
        top: 3px;
        left: 3px;
        font-size: 8px;
    }

    .close {
        font-family: glyphs;
        float: right;
        cursor: pointer;

        &:after {
            content: "\E656";
        }
    }

    > div:first-child .cartProduct {
        color: #fff;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 103px 22px 1fr;
        grid-template-columns: 103px 1fr;
        grid-gap: 0px 22px;
        margin: 18px 0 25px 0;

        .productImage {
            height: 129px;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: #FFFFFF;
            margin-bottom: 25px;
        }

        .productDetails {
            -ms-grid-column: 3;
            padding-top: 0;
            display: grid;
            position: relative;
            grid-template-columns: 1fr 30px;
            grid-template-rows: min-content min-content min-content min-content;
            grid-template-areas:
                "productName delete"
                "productCat ."
                "size ."
                "price .";
            grid-row-gap: 7px;

            .productPrice {
                font-size: 16px;
                color: #585858;
                grid-area: price;
            }
            .productSize {
                font-size: 16px;
                color: #585858;
                grid-area: size;
            }
        }
    }

    .productDelete {
        border: 0;
        background: none;
        grid-area: delete;
        cursor: pointer;
        &:hover {
            opacity: 0.6;
        }
    }

    .button {
        display: block;
        float: none;
        margin-bottom: 0;
        color: white;
    }
    .cartMessageContainer {
        opacity: 1;
        margin-bottom: 20px;
    }
    @keyframes killMessage {
        0%{
          opacity: 1;
        }
        70%{
          opacity: 1;
        }
        100%{
          opacity: 0;
        }
      }
}

.fadeOut {
    opacity: 0;
    animation-name: killMessage;
    animation-duration: 5.0s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
}

.cartUI.openCart {
    visibility: visible;
    opacity: 1;
    max-height: calc(100vh - 158px);
    overflow-y: auto;
}

.darkOverlay {
    background: rgba(0, 0, 0, 0.75);
    z-index: 1;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms, visibility 500ms;
}

.darkOverlay.active {
    visibility: visible;
    opacity: 1;
}

.message {
    background: #e4e4e4;
    padding: 13px 0;
    display: none;
    justify-content: center;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &:before {
        display: inline-block;
        content: "\E602";
        color: red;
        font-family: glyphs;
        font-size: 18px;
        margin-right: 10px;
    }

    .close {
        cursor: pointer;
        font-family: glyphs;
        float: right;
        cursor: pointer;
        position: absolute;
        top: 14px;
        right: 15px;

        &:after {
            content: "\E656";
        }
    }
}

.message.closed {
    display: none;
}

.message.open {
    display: flex;
}

main {
    position: relative;
    /* overflow: hidden; */
}

/*

 __   __     ______     __   __
/\ "-.\ \   /\  __ \   /\ \ / /
\ \ \-.  \  \ \  __ \  \ \ \'/
 \ \_\\"\_\  \ \_\ \_\  \ \__|
  \/_/ \/_/   \/_/\/_/   \/_/


*/
.mainNav {
    /*
    position: absolute;
    right: -266px;
    width: 225px;
    background: white;
    opacity: 0;
    padding: 13px 15px 0 20px;
    border-right: 1px solid $lightGrey;
    transition: right .5s, opacity 250ms ease-in-out;
    z-index: 1;
*/

    border-left: 1px solid #d4d4d4;
    position: fixed;
    right: 0;
    width: 225px;
    background: #fff;
    height: 100%;
    min-height: 100vh;
    opacity: 0;
    overflow-y: auto;
    padding: 13px 15px 0 20px;
    border-right: 1px solid #d4d4d4;
    transition: right 0.5s, opacity 250ms ease-in-out;
    z-index: 1;

    @media #{$for-tablet-landscape-up} {
        border-left-color: #fff;
        box-sizing: border-box;
        left: 0;
        margin-top: -63px;
        position: fixed;
        padding: 76px 15px 0 20px;
        right: auto;
        display: block;
        width: 189px;
        z-index: 1;
        /* position: relative;
        right: 0;
        display: block;
        width: 189px; */
    }

    &.hidden {
        display: none;
        @media #{$for-tablet-landscape-up} {
            display: block;
        }
    }

    &.loaded {
        opacity: 1;
    }

    .nav1 {
        font-family: robotoBold;
        font-size: 17px;
    }

    .nav2 {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
    }

    .nav3 {
        font-size: 12px;
        color: #585858;
    }
}

.mainNav > ul > li {
    border-bottom: 1px solid $lightGrey;
}

/* TREEVIEW (nav) */

$lightGrey: #d4d4d4;
$darkGrey: #6d6d6d;

.treeview {
    opacity: 1;
    padding-bottom: 50px;

    .treeview-nodes {
        list-style: none;
        padding-left: 0;
        margin: 0;

        & > .treeview-node {
            border-bottom: 1px solid $lightGrey;
            padding-bottom: 6px;

            &:last-of-type {
                border-bottom: none;
            }
        }

        .treeview-node {
            a,
            label {
                color: black;
                display: block;
                position: relative;

                &:hover {
                    color: $darkGrey;

                    &:before {
                        background: $lightGrey;
                        content: "";
                        height: 100%;
                        left: -20px;
                        position: absolute;
                        top: -2px;
                        width: 5px;
                    }
                }

                &.selected {
                    &:hover {
                        color: black;
                        cursor: default;
                    }

                    &:before {
                        background: black;
                        content: "";
                        height: 100%;
                        left: -20px;
                        position: absolute;
                        top: -2px;
                        width: 5px;
                    }
                }

                &.nav1 {
                    padding: 13px 0;
                    position: relative;
                    text-transform: uppercase;

                    &:after {
                        content: "";
                    }

                    &.expandable {
                        &.expanded {
                            &:after {
                                content: "\E607";
                            }
                        }

                        &:after {
                            content: "\E60A";
                            color: black;
                            font-family: glyphs;
                            font-size: 20px;
                            position: absolute;
                            right: 0;
                            top: 11px;
                        }
                    }
                }

                &.nav2 {
                    padding: 10px 0 15px 0;
                }

                &.nav3 {
                    padding: 3px 0;
                }
            }

            .treeview-node-content {
                ul {
                    list-style: none;
                    padding: 0 0 13px 0;
                    margin: 0;

                    &.bordered {
                        border-bottom: 1px solid $lightGrey;
                    }
                }
            }
        }
    }
}

/*   /treeview   */

/* nav-links */
.mainNav .nav-links {
    border-top: 1px solid #d4d4d4;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        border-bottom: none;
        padding: 13px 0;
        a,
        label {
            color: #000;
            font-family: robotoBold;
            font-size: 17px;
            position: relative;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
@media only screen and (min-width: 900px) {
    .mainNav .nav-links label.context-menu {
        display: none;
        padding-top: 0;
    }
}

/* -----------------------------------------------------------------

     _
    | |
    | |
    |_|
     _
    |_|   UPDATE THESE STYLES WHEN YOU ADD A NEW PAGE!


-------------------------------------------------------------------*/
/* TURN OFF THE NAV ON THESE PAGES: */
.welcome .mainNav,
.login .mainNav,
.choice .mainNav,
.productdetail .mainNav,
.thankyou .mainNav,
.packagedetail .mainNav,
.checkout .mainNav,
.goodbye .mainNav,
.outofservice .mainNav,
.promoContainer .mainNav {
    display: none;
}

.login,
.choice,
.goodbye,
.outofservice,
.promoContainer {
    padding-top: 0;

    .header {
        display: none;
    }
}

/*  ENABLE CORRECT HEADER FOR PAGE */
.welcome .welcomeHeader {
    display: block;
}

.storefront .storeHeader,
.storecategory .storeHeader,
.productdetail .storeHeader,
.packagedetail .storeHeader,
.checkout .storeHeader,
.thankyou .storeHeader {
    display: -ms-grid;
    display: grid;
}

main .productPane {
    padding: 27px 16px 35px 16px;

    @media #{$for-tablet-landscape-up} {
        padding: 27px 35px 35px 35px;
    }
}
/*

 ______   ______     ______     _____     __  __     ______     ______
/\  == \ /\  == \   /\  __ \   /\  __-.  /\ \/\ \   /\  ___\   /\__  _\
\ \  _-/ \ \  __<   \ \ \/\ \  \ \ \/\ \ \ \ \_\ \  \ \ \____  \/_/\ \/
 \ \_\    \ \_\ \_\  \ \_____\  \ \____-  \ \_____\  \ \_____\    \ \_\
  \/_/     \/_/ /_/   \/_____/   \/____/   \/_____/   \/_____/     \/_/


*/
.productImage {
    background-size: cover;
}

.productName {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #585858;
}

.productPrice,
.productQuantity {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #585858;
}

.productShortDesc,
.productSize {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #9a9a9a;
}
/* Product */
.productDetails {
    padding-top: 16px;

    .productName {
    }

    .productPrice {
        //float: right;
        display: block;
        margin-top: 20px;
    }

    .productShortDesc {
        display: block;
    }
}

/* PROMO PAGE */
.promo {
    padding: 0;
    height: 100%;
    .promoContainer {
        background-size: cover;
        height: 100vh;
        position: relative;

        #promoContent {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
            background: white;
            width: 85%;
            margin: 0 auto;
            padding: 51px 30px 30px;
            max-width: 767px;

            h1 {
                margin-top: 0;
            }
            .button {
                margin-right: 20px;
            }
        }
    }
}

/* Welcome Page */
.welcomeMain {
    height: calc(100vh - 63px);

    @media #{$for-tablet-landscape-up} {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 43% 1fr;
        grid-template-columns: 43% 1fr;
        grid-template-areas: "welcome pic";
    }

    .welcomePic {
        height: 215px;
        background: #a5b1c3;
        grid-area: pic;
        position: relative;
        background-size: cover;
        background-position: center center;
        -ms-grid-column: 2;

        .logo {
            content: "";
            color: black;
            position: absolute;
            top: 20px;
            right: 40px;
            font-style: normal;
        }

        @media #{$for-tablet-landscape-up} {
            height: 100%;
        }
    }

    article {
        grid-area: welcome;
        padding: 107px 49px 49px 49px;
        -ms-grid-column: 1;

        @media #{$for-tablet-landscape-up} {
            padding-top: 77px;
        }

        .disclaimer {
            font-size: 10px;
            color: #868686;
        }

        .brandButton {
            margin-top: 44px;
        }
    }
}
/*MAIN GRID*/
.storefront main,
.storecategory main {
    @media #{$for-tablet-landscape-up} {
        display: -ms-grid;
        /* display: grid; */
        -ms-grid-columns: 225px 1fr;
        grid-template-columns: 225px 1fr;

        .mainNav {
            -ms-grid-column: 1;
        }

        .view {
            -ms-grid-column: 2;
            margin-left: 225px;
        }
    }
}
/* Storefront Page */
.featuredPackage {
    background: #a5b1c3;
    box-sizing: border-box;
    overflow: auto;
    max-width: 100%;
    background-size: cover;
    background-position: right top;

    .featuredPackageInner {
        width: 100%;
        padding-bottom: 33%;

        .details {
            padding: 32px 30px;

            h1 {
                margin-top: 0;
                color: black;
            }
        }
    }
}

.storefrontProductRow {
    margin-top: 0;
    @media #{$for-tablet-landscape-up} {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 10px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 25px 10px;
    }

    .gridRow1 {
        -ms-grid-row: 1;
    }

    .gridRow2 {
        -ms-grid-row: 2;
    }

    .gridRow3 {
        -ms-grid-row: 3;
    }

    .gridRow4 {
        -ms-grid-row: 4;
    }

    .nonEssentialWorker:first-child {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }

    .nonEssentialWorker:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }

    .nonEssentialWorker:nth-child(3n) {
        -ms-grid-column: 1;
    }

    .nonEssentialWorker:nth-child(4n) {
        -ms-grid-column: 3;
    }

    .storefrontProduct {
        display: block;

        .productImage {
            background-color: #a5b1c3;
            width: 100%;
            padding-bottom: 150%;
        }

        .productPrice {
        }
    }
}
/* Store Category */
.storecategory {
    .breadcrumbs {
        color: #666666;
        font-size: 16px;
        text-transform: capitalize;

        a {
            &:hover {
                opacity: 0.75;
            }
        }

        a:first-child {
            color: black;
        }
    }

    .productGrid {
        padding-top: 10px;
        grid-row-gap: 27px;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 27px 1fr 27px 1fr;
        grid-template-columns: 1fr;
        grid-row-gap: 27px;

        .gridRow1 {
            -ms-grid-row: 1;
        }

        .gridRow2 {
            -ms-grid-row: 2;
        }

        .gridRow3 {
            -ms-grid-row: 3;
        }

        .gridRow4 {
            -ms-grid-row: 4;
        }

        .gridRow5 {
            -ms-grid-row: 5;
        }

        .gridRow6 {
            -ms-grid-row: 6;
        }

        .gridRow7 {
            -ms-grid-row: 7;
        }

        .gridRow8 {
            -ms-grid-row: 8;
        }

        .gridRow9 {
            -ms-grid-row: 9;
        }

        .gridRow10 {
            -ms-grid-row: 10;
        }

        .gridRow11 {
            -ms-grid-row: 11;
        }

        .gridRow12 {
            -ms-grid-row: 12;
        }

        .gridRow13 {
            -ms-grid-row: 13;
        }

        .gridRow14 {
            -ms-grid-row: 14;
        }

        .gridRow15 {
            -ms-grid-row: 15;
        }

        .gridRow16 {
            -ms-grid-row: 16;
        }

        .gridRow17 {
            -ms-grid-row: 17;
        }

        .gridRow18 {
            -ms-grid-row: 18;
        }

        .gridRow19 {
            -ms-grid-row: 19;
        }

        .gridRow20 {
            -ms-grid-row: 20;
        }

        .gridRow21 {
            -ms-grid-row: 21;
        }

        .gridRow22 {
            -ms-grid-row: 22;
        }

        .gridRow23 {
            -ms-grid-row: 23;
        }

        .gridRow24 {
            -ms-grid-row: 24;
        }

        .gridRow25 {
            -ms-grid-row: 25;
        }

        .gridRow20 {
            -ms-grid-row: 20;
        }

        .gridRow26 {
            -ms-grid-row: 26;
        }

        .gridRow27 {
            -ms-grid-row: 27;
        }

        .gridRow28 {
            -ms-grid-row: 28;
        }

        .gridRow29 {
            -ms-grid-row: 29;
        }

        .gridRow30 {
            -ms-grid-row: 30;
        }

        .gridRow31 {
            -ms-grid-row: 31;
        }

        .gridRow32 {
            -ms-grid-row: 32;
        }

        .gridRow33 {
            -ms-grid-row: 33;
        }

        .gridRow34 {
            -ms-grid-row: 34;
        }

        .gridRow35 {
            -ms-grid-row: 35;
        }

        .gridRow36 {
            -ms-grid-row: 36;
        }

        .gridRow37 {
            -ms-grid-row: 37;
        }

        .gridRow38 {
            -ms-grid-row: 38;
        }

        .gridRow39 {
            -ms-grid-row: 39;
        }

        .gridRow40 {
            -ms-grid-row: 40;
        }

        .gridRow41 {
            -ms-grid-row: 41;
        }

        .gridRow42 {
            -ms-grid-row: 42;
        }

        .gridRow43 {
            -ms-grid-row: 43;
        }

        .gridRow44 {
            -ms-grid-row: 44;
        }

        .gridRow45 {
            -ms-grid-row: 45;
        }

        .gridRow46 {
            -ms-grid-row: 46;
        }

        .gridRow47 {
            -ms-grid-row: 47;
        }

        .gridRow48 {
            -ms-grid-row: 48;
        }

        .gridRow49 {
            -ms-grid-row: 49;
        }

        .gridRow50 {
            -ms-grid-row: 50;
        }

        .nonEssentialWorker:first-child {
            -ms-grid-column: 1;
        }

        .nonEssentialWorker:nth-child(2n) {
            -ms-grid-column: 3;
        }

        .nonEssentialWorker:nth-child(3n) {
            -ms-grid-column: 5;
        }

        @media #{$for-tablet-landscape-up} {
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 10px;
        }

        .productImage {
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: #FFFFFF;
            width: 100%;
            padding-bottom: 100%;
        }
    }
}

/* Product Detail */
.productDetailPane {
    .productDetailGrid {
        margin-bottom: 64px;

        @media #{$for-tablet-landscape-up} {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 16px 2fr;
            grid-template-columns: 1fr 2fr;
            grid-column-gap: 16px;
            grid-template-areas: "pic details";

            .nonEssentialWorker {
                //-ms-grid-column: 1;
            }

            .productDetailPane {
                -ms-grid-column: 3;
            }
        }

        .productImage {
            width: 100%;
            padding-bottom: 125%;
            position: relative;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: #FFFFFF;
        }

        .views {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 16.3% 2% 16.3% 2% 16.3% 2% 16.3% 2% 16.3% 2% 16.3%;
            grid-template-columns: 16.3% 16.3% 16.3% 16.3% 16.3% 16.3%;
            -ms-grid-rows: 20% 2% 20%;
            grid-template-rows: 20% 20%;
            gap: 2% 2%;
            margin-top: 7px;

            img {
                max-width: 100%;
            }
        }

        .views > *:nth-child(1) {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
        }

        .views > *:nth-child(2) {
            -ms-grid-row: 1;
            -ms-grid-column: 3;
        }

        .views > *:nth-child(3) {
            -ms-grid-row: 1;
            -ms-grid-column: 5;
        }

        .views > *:nth-child(4) {
            -ms-grid-row: 1;
            -ms-grid-column: 7;
        }

        .views > *:nth-child(5) {
            -ms-grid-row: 1;
            -ms-grid-column: 9;
        }

        .views > *:nth-child(6) {
            -ms-grid-row: 1;
            -ms-grid-column: 11;
        }

        .views > *:nth-child(7) {
            -ms-grid-row: 3;
            -ms-grid-column: 1;
        }

        .views > *:nth-child(8) {
            -ms-grid-row: 3;
            -ms-grid-column: 3;
        }

        .views > *:nth-child(9) {
            -ms-grid-row: 3;
            -ms-grid-column: 5;
        }

        .views > *:nth-child(10) {
            -ms-grid-row: 3;
            -ms-grid-column: 7;
        }

        .views > *:nth-child(11) {
            -ms-grid-row: 3;
            -ms-grid-column: 9;
        }

        .views > *:nth-child(12) {
            -ms-grid-row: 3;
            -ms-grid-column: 11;
        }

        .productInfo {
            .catName {
                font-size: 14px;
                color: #7b7b7b;
            }

            .productPrice {
                float: right;
                font-size: 16px;
                color: #333333;
            }

            .productName {
                font-size: 30px;
                color: black;
                display: block;
                margin-bottom: 28px;
            }
        }

        .productConfig {
            @media #{$for-tablet-landscape-up} {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 24px 1fr;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 24px;

                .desc-color {
                    -ms-grid-column: 1;
                }

                .size-logo {
                    -ms-grid-column: 3;

                    input[type="text"] {
                        text-align: center;
                        border-radius: 5px;
                        border: 1px solid #555;
                        margin-top: 8px;
                        padding: 10px;
                        font-size: 14px;
                        width: calc(100% - 20px);
                        &::placeholder {
                            color: black;
                        }

                        &::-webkit-input-placeholder {
                            color: grey;
                        }

                        &::-moz-placeholder {
                            color: grey;
                        }

                        &::-ms-input-placeholder {
                            color: grey;
                        }

                        &.validated-field {
                            border: 1px solid #ccc;
                        }

                        &.invalid-field {
                            border-color: red;

                            &::placeholder {
                                color: red;
                            }

                            &::-webkit-input-placeholder {
                                color: red;
                            }

                            &::-moz-placeholder {
                                color: red;
                            }

                            &::-ms-input-placeholder {
                                color: red;
                            }
                        }
                    }
                }
            }

            p {
                font-size: 16px;
                line-height: 146.2%;
                color: #585858;
            }

            .colors {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 8px 1fr 8px 1fr 8px 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 8px 8px;

                .color1 {
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                }

                .color2 {
                    -ms-grid-row: 1;
                    -ms-grid-column: 3;
                }

                .color3 {
                    -ms-grid-row: 1;
                    -ms-grid-column: 5;
                }

                .color4 {
                    -ms-grid-row: 1;
                    -ms-grid-column: 7;
                }

                .color {
                    width: 100%;
                    padding-bottom: 100%;
                    cursor: pointer;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                    display: block;
                    border: 1px solid white;
                }

                .highlighted span {
                    border: 1px solid rgb(0, 0, 0);
                    border-radius: 5px;
                }

                input {
                    position: absolute;
                    height: 0;
                    width: 0;
                    overflow: hidden;
                    opacity: 0;
                }

                .color:hover,
                input:checked ~ .color {
                    border: 1px solid black;
                    border-radius: 5px;
                }
            }

            .configSize {
                margin-bottom: 26px;
            }

            .sizingLink {
                float: right;
            }

            .sizes {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 8px 1fr 8px 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                -ms-grid-rows: 1fr 8px 1fr 8px 1fr;
                grid-template-rows: 1fr;
                grid-gap: 8px 8px;
                border-radius: 10px;
                border: 1px solid white;

                .sizeContainer input {
                    opacity: 0;
                    position: absolute;
                    z-index: 0;
                    height: 0;
                    cursor: pointer;
                }

                .size {
                    width: 100%;
                    padding: 19% 0;
                    border: 1px solid #e8e8e8;
                    border-radius: 5px;
                    color: black;
                    text-align: center;
                    cursor: pointer;
                    display: block;
                    &:hover {
                        &:not([disabled]) {
                            border: 1px solid black;
                        }
                    }
                    &[disabled] {
                        color: #e8e8e8;
                        background-color: #ff0000;;
                        background: linear-gradient(to top right, #fff calc(50% - 1px), #e8e8e8, #fff calc(50% + 1px) )
                    }
                }

                .soldOut {
                    width: 100%;
                    padding: 19% 0;
                    border: 1px solid #e8e8e8;
                    border-radius: 5px;
                    text-align: center;
                    display: block;
                    color: #e8e8e8;
                }

                input:checked ~ .size {
                    border: 1px solid black;
                }
            }

            .sizes > *:nth-child(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
            }

            .sizes > *:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 3;
            }

            .sizes > *:nth-child(3) {
                -ms-grid-row: 1;
                -ms-grid-column: 5;
            }

            .sizes > *:nth-child(4) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }

            .sizes > *:nth-child(5) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
            }

            .sizes > *:nth-child(6) {
                -ms-grid-row: 3;
                -ms-grid-column: 5;
            }

            .sizes > *:nth-child(7) {
                -ms-grid-row: 5;
                -ms-grid-column: 1;
            }

            .sizes > *:nth-child(8) {
                -ms-grid-row: 5;
                -ms-grid-column: 3;
            }

            .sizes > *:nth-child(9) {
                -ms-grid-row: 5;
                -ms-grid-column: 5;
            }

            .decision {
                display: none;
            }

            .eventchoice {
                img {
                   // margin: 0;
                }

                .logoPlacement {
                    pointer-events: none;
                    padding-right: 8px;
                    opacity: 0;
                    transition: opacity 500ms, visibility 500ms;
                    text-transform: capitalize;
                }

                .logoPlacementLink {
                    display: inline-block;
                }

                .logoPlacementLink span {
                    opacity: 1;
                    border: none;
                    display: inline-block;
                    background: black;
                    padding: 5px 14px;
                    border-radius: 17px;
                    color: white;
                    font-size: 11px;
                    margin-top: 3px;
                }
            }

            .container {
                grid-template-columns: 1px 33px 104px 1fr;
                -ms-grid-columns: 1px 12px 33px 12px 166px 12px 1fr;
                border-bottom: 1px solid #c2c2c2;

                input {
                    -ms-grid-column: 1;
                }

                .checkmark {
                    margin-top: auto;
                    margin-bottom: auto;
                    -ms-grid-column: 3;
                }

                img {
                    width: 100%;
                    margin-top: auto;
                    margin-bottom: auto;
                    -ms-grid-column: 5;
                }

                .logoPlacement {
                    -ms-grid-column: 7;
                    display: block;
                }
            }

            .nonEssentialWorker:last-child .container {
                border-bottom: none;
            }

            .container input:checked ~ .logoPlacement {
                opacity: 1;
                pointer-events: auto;
            }

            .logoCrest {
                clear: both;

                img {
                    width: 50%;
                    height: auto;
                }
            }

            .buyButton {
                margin: 40px 0 0 0;
                display: block;
                width: 100%;
            }
        }
    }
}
// productDetailPane
/* Psckage Detail*/
.packagedetail {
    .featuredPackage {
        background-color: #a5b1c3;
        box-sizing: border-box;
        overflow: auto;
        max-width: 100%;
        margin-bottom: 28px;
        overflow: hidden;
        background-size: cover;
        background-position: center top;

        .featuredPackageInner {
            width: 100%;
            padding-bottom: 11.67%;

            .details {
                position: relative;
                top: 111px;
                padding: 32px 30px;

                h1 {
                    margin-top: 0;
                    color: white;
                }
            }
        }
    }

    .packageHeaderInfo {
        margin-bottom: 28px;

        .itemCount {
            color: #a2a2a2;
        }

        .packagePrice {
            float: right;
            font-size: 16px;
            color: #585858;
        }
    }

    .productPrice,
    .productDetailPane .productDetailGrid .productConfig .buyButton {
        display: none;
    }

    .buyPackageButton {
        margin: 12px 0 24px 0;
        float: right;
    }
}
/* Checkout*/
.checkoutGrid {
    .emptyCartMessage {
        padding: 20px;
        background: #f5f5f5;
        text-align: center;

        &:before {
            font-family: glyphs;
            content: "\E60E";
            display: inline-block;
            margin-right: 12px;
            margin-right: 12px;
            font-size: 21px;
            vertical-align: text-bottom;
        }
    }

    @media #{$for-tablet-landscape-up} {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 2.5fr 16px 1fr;
        grid-template-columns: 2.5fr 1fr;
        grid-gap: 0px 16px;
    }

    padding-top: 15px;

    .products {
        .checkoutProduct {
            @media #{$for-tablet-portrait-up} {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 0.25fr 16px 1fr;
                grid-template-columns: 0.25fr 1fr;
                grid-gap: 0px 16px;
                line-height: 1.5em;
                margin-bottom: 40px;
            }

            .productImage {
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                background-color: #FFFFFF;
                width: 100%;
                padding: 50% 0;
                margin-bottom: 20px;

                @media #{$for-tablet-portrait-up} {
                    padding: 0;
                    width: 150px;
                    height: 175px;
                    margin-bottom: 0;
                }
            }

            .checkoutProductInfo {
                position: relative;
                -ms-grid-column: 2;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto auto auto auto auto auto;
                grid-template-areas: "name name" "desc desc" "color color" "size position" "quantity tape" "remove removeTape";

                @media #{$for-tablet-portrait-up} {
                    grid-template-columns: 1fr 1fr 1fr auto;
                    grid-template-rows: auto auto auto auto auto;
                    grid-template-areas: "name name name name" "desc desc desc desc" "color color color color" "size quantity position tape" "remove remove remove removeTape";
                }

                .topLine {
                    grid-area: name;
                }

                .productPrice {
                    float: right;
                }

                .catName {
                    color: #9a9a9a;
                    display: block;
                    grid-area: desc;
                }

                .color {
                    color: #9a9a9a;
                    display: block;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    grid-area: color;
                }

                .checkoutLogoInfo {
                    float: right;
                    color: #9a9a9a;
                    clear: right;
                    grid-area: tape;
                }
                .configLogo {
                    grid-area: tape;
                    #embroidery {
                        grid-template-columns: 1px 33px 104px 1fr;
                        display: grid;
                        input ~ .logoPlacement {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }

                }

                .checkoutLogo {
                    img {
                        width: 19%;
                        vertical-align: middle;
                        min-width: 53px;
                        margin-right: 13px;
                    }

                    .logoCode {
                        display: inline-block;
                        margin-left: 12px;
                    }
                }

                .sizeSelector,
                .quantitySelector,
                .positionSelector,
                .tapeSelector {
                    position: relative;
                }

                .sizeSelector,
                .quantitySelector {
                    margin-right: 28px;
                }
                .positionSelector,
                .tapeSelector {
                    text-align: right;
                }

                .sizeSelector {
                    grid-area: size;
                }
                .quantitySelector {
                    grid-area: quantity;
                }
                .tapeSelector {
                    grid-area: tape;
                    margin-left: 28px;
                }
                .positionSelector {
                    grid-area: position;
                }

                .toggleLogo,
                .removeItem{
                    text-decoration: none !important; border-bottom: 1px dotted grey;
                    cursor: pointer;
                    &:hover { border-bottom: none; }
                 }

                label {
                    color: #9a9a9a;
                    line-height: 27px;
                    margin-right: 2px;
                    display: block;
                }

                button {
                    padding: 0;
                    background: none;
                    border: none;
                }

                .selectArrow {
                    font-family: glyphs;
                    display: block;
                    font-size: inherit;
                    line-height: inherit;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: 400;
                    text-decoration: none;
                    text-transform: none;
                    padding-right: 0;
                    position: absolute;
                    left: 45px;
                    text-align: right;
                    top: 32px;
                    color: #8d8d8d;
                    pointer-events: none;

                    &:before {
                        content: "";
                    }
                }

                .sizeOptions::-ms-expand,
                .tapeOptions::-ms-expand,
                .positionOptions::-ms-expand,
                .quantityOptions::-ms-expand {
                    display: none;
                }

                .sizeOptions,
                .tapeOptions,
                .positionOptions,
                .quantityOptions {
                    color: #9a9a9a;
                    display: inline-block;
                    padding: 5px 7px;
                    height: 31px;
                    padding-right: 0px;
                    min-height: inherit;
                    background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
                    border: 0px none;
                    border-radius: 2px;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    -webkit-appearance: none;
                    border: 1px solid rgb(209, 209, 209);
                }
                .sizeOptions,
                .quantityOptions {
                    width: 57px;
                }
                .positionOptions,
                .tapeOptions {
                    min-width: 7rem;
                }

                .remover {
                    grid-area: remove;
                    button {
                        cursor: pointer;
                    }
                }
                .logoToggler {
                    grid-area: removeTape;
                    button {
                        cursor: pointer;
                    }
                }

                .removeItem,
                .toggleLogo {
                    text-transform: capitalize;
                    text-decoration: underline;
                    display: block;
                    color: #9a9a9a;
                    font-size: 16px;
                    position: relative;
                    top: 5px;
                }
                .toggleLogo {
                    float: right;
                }
            }
        }

        .packages {
            .package {
                background: #f7f7f7;
                padding: 16px;
                margin-bottom: 16px;

                .packagePrice {
                    float: right;
                }

                .packageName {
                    margin: 0 0 16px 0;
                }

                .packageItemCount {
                    display: inline-block;
                    margin-left: 10px;
                    color: #aaaaaa;
                }

                div:nth-last-child(2) .checkoutProduct {
                    padding-bottom: 0;
                    border: none;
                }

                .checkoutProduct .productPrice {
                    display: none;
                }

                .checkoutProduct .removeItem .toggleLogo {
                    display: none;
                }

                .removePackage {
                    text-decoration: underline;
                    margin-top: 10px;
                    display: block;
                    color: #9a9a9a;
                    font-size: 16px;
                }

            }
        }

        .alacarte {
            padding: 16px;

            > div:last-child .checkoutProduct {
                padding-bottom: 30px;
                border: none;
                border-bottom: 1px solid #ddd;
            }
        }
    }

    .summary {
        padding-top: 16px;
        -ms-grid-column: 3;

        .summaryWrapper {
            @media #{$for-tablet-landscape-up} {
                position: sticky;
                top: 72px;
            }
        }

        a:visited {
            text-decoration: none;
            color: black;
        }

        h3 {
            margin: 2px 0 10px 0;
        }

        .subtotal {
            float: right;
        }

        .note {
            font-size: 14;
            font-style: italic;
            text-align: center;
            color: gray;
        }

        .balance {
            font-size: 36px;
            text-align: center;
            margin-bottom: 30px;
        }

        .balance.red {
            color: red;
        }

        .balance.grey {
            color: #585858;
        }

        .button {
            box-sizing: border-box;
            display: block;
            margin-bottom: 5px;
            min-height: 42px;
            position: relative;
            width: 100%;

            &.disabled {
                cursor: not-allowed;
                opacity: 0.75;
            }

            svg {
                display: inline;
                fill: white;
                height: 36px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 36px;
            }
        }

        .warning {
            position: relative;
            padding-left: 50px;
            padding-bottom: 20px;
            font-size: 14px;
            line-height: 129.2%;
            /* or 18px */
            color: #515458;

            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
            }
        }

        .dropShipLink {
            background: #ddd url(../images/ship.svg) no-repeat 17px 7px;
            background-size: 27px 27px;
            border-radius: 25px;
            padding: 12px 23px 12px 43px;
            display: block;
            margin-bottom: 7px;
            text-align: center;
            &:hover {  background-color: #e9e9e9;}
        }

        .smallText {
            color: #626262;
            font-size: 15px;
        }
    }/* summary */
}
/* Thank you*/
.thankyou {
    text-align: center;

    .storeHeader {
        .budgetInfo,
        .cart,
        .logout {
            display: none;
        }
    }

    .check {
        margin: 50px 0 43px 0;
        display: inline-block;
        position: relative;
        width: 45px;
        height: 43px;
        border-radius: 45px;
        font-family: glyphs;
        //background: @brandColor;
        &:after {
            color: white;
            content: "\E611";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            left: 0;
        }
    }

    h1 {
        margin: 0 0 37px 0;
    }
    img {
        max-width: 335px;
        max-height: 200px;
    }

    .shippingInfo {
        margin: 37px auto;
        border-top: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
        padding: 32px 0;
        max-width: 80%;

        @media #{$for-tablet-landscape-up} {
            max-width: 523px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0px 16px;
        }

        p {
            margin-top: 0;
            text-align: center;

            @media #{$for-tablet-landscape-up} {
                text-align: right;
            }
        }

        address {
            text-align: center;
            text-transform: capitalize;

            @media #{$for-tablet-landscape-up} {
                text-align: left;
            }

            strong {
                display: block;
            }
        }
    }
}
/* Login */
.login {
    .loginMain {
        position: relative;
        padding: 122px 25px 0 25px;

        @media #{$for-tablet-landscape-up} {
            padding: 0;
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 447px 1fr;
            grid-template-columns: 447px 1fr;
            grid-template-areas: "welcome pic";
            height: 100vh;
        }

        .loginPane {
            background: white;
            text-align: center;
            position: relative;
            display: grid;
            grid-template-rows: 1fr 328px;
            z-index: 2;

            .loginTop {
                padding: 80px 0 0 0;

                img {
                    margin-bottom: 52px;
                    max-width: 335px;
                    max-height: 200px;
                }

                .loginIntro {
                    text-transform: uppercase;
                    font-size: 24px;
                    font-family: robotoBold;
                    text-align: center;
                    display: inline-block;
                    margin: 0 auto 80px auto;
                    padding: 0px 60px;

                    @media #{$for-tablet-landscape-up} {
                        margin-bottom: 40px;
                    }

                    span {
                        font-family: robotoRegular;
                        display: block;
                    }
                }
            }

            #forgotPassword {
                color: rgb(255, 175, 175);
                display: block;
                position: relative;
                top: -15px;
            }

            form {
                background: #ffffff;
                width: calc(100% - 134px);
                padding: 54px 67px;
                position: relative;

                input[type="text"],
                input[type="password"] {
                    border-radius: 2px;
                    border: 0;
                    padding: 10px;
                    font-size: 14px;
                    margin-bottom: 23px;
                    width: calc(100% - 20px);

                    &::placeholder {
                        color: black;
                    }

                    &::-webkit-input-placeholder {
                        color: black;
                    }

                    &::-moz-placeholder {
                        color: black;
                    }

                    &::-ms-input-placeholder {
                        color: black;
                    }

                    &.validated-field {
                        border: 1px solid transparent;
                    }

                    &.invalid-field {
                        border-color: red;

                        &::placeholder {
                            color: red;
                        }

                        &::-webkit-input-placeholder {
                            color: red;
                        }

                        &::-moz-placeholder {
                            color: red;
                        }

                        &::-ms-input-placeholder {
                            color: red;
                        }
                    }
                }

                button {
                    display: block;
                    margin-bottom: 24px;
                    min-height: 42px;
                    width: 100%;
                    cursor: pointer;
                    outline: none;
                    padding-left: 0;
                    padding-right: 0;
                    position: relative;

                    &:disabled {
                        cursor: not-allowed;
                    }
                }

                svg {
                    display: inline;
                    fill: white;
                    height: 36px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 36px;
                }

                .retry-message {
                    left: 0;
                    position: absolute;
                    text-align: center;
                    top: 15px;
                    width: 100%;

                    label {
                        color: white;
                        line-height: 1.25em;
                    }
                }
            }

            .loginMessage {
                color: $brandContrast;
            }
        }

        .loginPic {
            background-size: cover;
            background-position: center;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            -ms-grid-column: 2;

            @media #{$for-tablet-landscape-up} {
                position: relative;
                top: unset;
                left: unset;
                padding: 0;
                display: grid;
                grid-template-columns: 447px 1fr;
                grid-template-areas: "welcome pic";
                height: 100%;
                //background-position: -30% center;
            }

            .logo {
                position: absolute;
                top: 20px;
                right: 40px;
            }
        }
    }
}
/* Account Selector */
.account-selector {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .account-selector-greeting {
        flex: 1 1 auto;

        .logo {
            color: black;
        }
    }

    .account-selector-choices {
        flex: 1 1 auto;
        min-height: 70px;
        overflow-x: hidden;
        overflow-y: auto;

        .account-choice-container {
            border: 1px solid transparent;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &.invalid {
                border-color: red;
            }
        }

        .account-choice {
            flex: 0 0 49%;
            max-width: 49%;
            min-width: 49%;
            padding: 5px 5px 5px 0;
            vertical-align: top;

            &:hover {
                .checkmark {
                    background-color: #ccc;
                }
            }

            span {
                height: 25px;
                line-height: 25px;
                vertical-align: top;
            }
            /* Hide the browser's default radio button */
            input {
                opacity: 0;
                cursor: pointer;
                margin: 0;
                padding: 0;
                width: 0;

                &:checked {
                    & ~ .checkmark {
                        background-color: black;
                    }
                }
            }
            /* Create a custom radio button */
            .checkmark {
                border: 1px solid #ccc;
                display: inline-block;
                margin-right: 12px;
                width: 25px;
                background-color: #eee;
                border-radius: 50%;
                position: relative;
                /* Create the indicator (the dot/circle - hidden when not checked) */
                &:after {
                    content: "\E611";
                    color: white;
                    font-family: glyphs;
                    position: absolute;
                    display: block;
                    left: 6px;
                    font-size: 14px;
                }
            }
        }
    }

    .account-selector-buttonbar {
        box-sizing: border-box;
        flex: 0 0 60px;
        //line-height: 60px;
        max-height: 60px;
        min-height: 60px;
        padding-top: 9px;

        button {
            min-height: 42px;
            min-width: 118px;
            outline: none;
            position: relative;

            svg {
                display: inline;
                fill: white;
                height: 36px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 36px;
            }
        }

        label {
            color: red;
            display: inline-block;
            margin-left: 12px;
        }
    }
}
/* Choice */
.choiceMain {
    height: 100vh;

    @media #{$for-tablet-landscape-up} {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 43% 1fr;
        grid-template-columns: 43% 1fr;
        grid-template-areas: "welcome pic";
    }

    .choiceGreeting {
        margin: auto;
        position: relative;
        padding: 0 40px 40px 40px;

        .logo {
            color: black;
            position: absolute;
            top: -80px;
        }
    }
}
/* LOGO CHOOSER */
.eventchoice {
    margin: auto;
    -ms-grid-column: 2; /* fix for IE - will this break logo placement? */
    //width: 90%;
    img {
        /* max-width: 150px;
            max-height: 70px;*/
        width: 65%;
        margin-top: auto;
        margin-bottom: auto;
    }

    span {
        margin: auto 0;
        display: block;
    }

    .button {
        margin-top: 50px;
    }
}
/* The container */
.container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1px 33px 177px 1fr;
    grid-gap: 12px;
    padding: 18px 0;
    border-bottom: 1px solid #c2c2c2;
}

.container:last-child {
    border-bottom: none;
}

.container:hover {
    background: #ededed;
}
/* Hide the browser's default radio button */
.container input {
    opacity: 0;
    cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    position: relative;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: green;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "\E611";
    color: white;
    font-family: glyphs;
    position: absolute;
    display: block;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 5px;
    left: 6px;
    font-size: 14px;
    line-height: 14px;
}

#multipleEmbroidery {
    grid-template-columns: 1px 33px 1fr;
    padding: 0;
    input ~ .logoPlacement {
        opacity: 1;
        pointer-events: auto;
    }
    span {
      margin: auto 0;
      text-align: left;
    }
}

#embroidery {
    grid-template-columns: 1px 33px 104px 1fr;
    input ~ .logoPlacement {
        opacity: 1;
        pointer-events: auto;
    }
}

.radioContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2px;
}

.embroideryOption {
  margin: 4px 0px;
  width: 48%;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  &:hover {
    border-color: black;
  }
  &:has(input:checked) {
    border-color: black;
  }
  input {
    opacity: 0;
    margin: 0;
    height: 0px;
    width: 0px;
  }
  h3 {
    text-align: center;
  }
  .tapeImage {
    text-align:center;
  }
  img {
    height: 80px;
    max-width: 120px;
  }
}

/* Goodbye */
.goodbye {
    text-align: center;
    padding-top: 70px;

    .logo {
        color: black;
        margin: 0 auto;
        &:before {
            margin: 0 auto;
        }
    }
}
/* Size Chart */
#overlay {
    //display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    border: 2px solid black;
    background-color: #ffffff;
    width: 80%;
    height: auto;
    z-index: 100;

    @media #{$for-tablet-landscape-up} {
        width: 50%;
    }
}

#custom-modal {
    .close {
        font-family: glyphs;
        float: right;
        cursor: pointer;

        &:after {
            content: "\E656";
        }
    }

    #fade {
        width: 100%;
        height: 100%;
        z-index: 90;
        opacity: 0.75;
        position: fixed;
        left: 0%;
        top: 0%;
        background-color: black;
    }

    table {
        margin-top: 20px;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        table-layout: fixed;

        td {
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            height: 40px;
        }

        input {
            opacity: 0;
            position: absolute;
            z-index: 0;
            height: 0;
            //cursor: pointer;
        }
        /*                                       SIZE CHART HOVER
        td span:hover,
        input:checked ~ span {
            color: @brandContrast;
            background: @brandColor;
            //box-shadow: inset 0 0 10px #ccc;
            cursor: pointer;
        }
*/
        span {
            display: block;
            padding: 12px 0;
        }

        tr:nth-child(even) {
            background-color: #dddddd;
        }
    }
}

.placementChart {
    #overlay {
        //min-height: 475px;
    }

    .button {
        margin-top: 20px;
    }

    .placementOptions {
        #logoApparel {
        }

        .dotsWrapper {
            max-height: 253px;
            overflow: hidden;
            position: relative;
            margin: 10px auto 8px auto;
            max-width: 500px;

            &:after {
                position: absolute;
                background: $brandColor;
                border-radius: 100%;
                width: 5%;
                padding-top: 5%;
            }

            .dotsApparel {
                position: relative;
                width: 100%;
                padding-top: 45%;
                max-width: 500px;
                box-sizing: content-box;
                background-repeat: no-repeat;
                margin: 20px auto;
                display: block;
                /* background-image: url(../images/logoApparel.png);      FIND */
                background-size: 100%;
            }
        }
    }

    .LC {
        .dotsWrapper:after {
            content: "";
            top: 28%;
            left: 29%;
        }
    }

    .RC {
        .dotsWrapper:after {
            content: "";
            top: 28%;
            left: 16%;
        }
    }

    .RS {
        .dotsWrapper:after {
            content: "";
            top: 23%;
            left: 3%;
        }
    }

    .LS {
        .dotsWrapper:after {
            content: "";
            top: 23%;
            left: 41%;
        }
    }

    .B {
        .dotsWrapper:after {
            content: "";
            top: 28%;
            left: 73%;
        }
    }

    .LLH {
        .dotsWrapper:after {
            content: "";
            top: 79%;
            left: 30%;
        }
    }

    .LRH {
        .dotsWrapper:after {
            content: "";
            top: 79%;
            left: 15%;
        }
    }

    .placementOptionsWrapper {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        -ms-grid-rows: 1fr 1fr 1fr;
        grid-template-rows: auto;

        > *:nth-child(1) {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
        }

        > *:nth-child(2) {
            -ms-grid-row: 1;
            -ms-grid-column: 2;
        }

        > *:nth-child(3) {
            -ms-grid-row: 1;
            -ms-grid-column: 3;
        }

        > *:nth-child(4) {
            -ms-grid-row: 2;
            -ms-grid-column: 1;
        }

        > *:nth-child(5) {
            -ms-grid-row: 2;
            -ms-grid-column: 2;
        }

        > *:nth-child(6) {
            -ms-grid-row: 2;
            -ms-grid-column: 3;
        }

        > *:nth-child(7) {
            -ms-grid-row: 3;
            -ms-grid-column: 1;
        }

        > *:nth-child(8) {
            -ms-grid-row: 3;
            -ms-grid-column: 2;
        }

        > *:nth-child(9) {
            -ms-grid-row: 3;
            -ms-grid-column: 3;
        }

        .placementCell {
        }

        label {
            display: block;
            padding: 9px 7px 7px 36px;
            position: relative;
            cursor: pointer;

            &:hover {
                background: #ededed;

                .checkmark {
                    background: #ccc;
                }
            }
        }

        input {
            opacity: 0;
            cursor: pointer;
            position: absolute;
        }

        .checkmark {
            position: absolute;
            left: 5px;
            top: 4px;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-color: #eee;

            &:after {
                content: "\E611";
                color: white;
                font-family: glyphs;
                position: absolute;
                display: block;
                top: 5px;
                left: 6px;
                font-size: 14px;
                height: 25px;
                width: 25px;
                left: 6px;
                top: 6px;
                font-size: 13px;
            }
        }

        input:checked ~ .checkmark {
            background-color: black;
        }
    }
}
/*

 ______     __  __     ______      ______     ______      ______     ______     ______     __   __   ______     ______
/\  __ \   /\ \/\ \   /\__  _\    /\  __ \   /\  ___\    /\  ___\   /\  ___\   /\  == \   /\ \ / /  /\  ___\   /\  ___\
\ \ \/\ \  \ \ \_\ \  \/_/\ \/    \ \ \/\ \  \ \  __\    \ \___  \  \ \  __\   \ \  __<   \ \ \'/   \ \ \____  \ \  __\
 \ \_____\  \ \_____\    \ \_\     \ \_____\  \ \_\       \/\_____\  \ \_____\  \ \_\ \_\  \ \__|    \ \_____\  \ \_____\
  \/_____/   \/_____/     \/_/      \/_____/   \/_/        \/_____/   \/_____/   \/_/ /_/   \/_/      \/_____/   \/_____/


*/
body {
    &.outofservice {
        height: 100vh;

        main {
            height: 100%;

            .view {
                height: 100%;
            }
        }
    }
}

.outofservice-message {
    left: 50%;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);

    .logo {
        color: black;
    }

    h1 {
        white-space: nowrap;
    }
}

body {
    &.fourOhFour {
        height: 100vh;
        text-align: center;
        main {
            height: 100%;

            .view {
                height: 100%;
            }
        }
     }
}



/* SET PASSWORD */

.setPassword, .forgotPassword {

    .main{
        padding: 50px;
        max-width: 400px; margin: 0 auto;

        form {
            padding-top: 30px;

            input[type="password"], input[type="text"] {
                border-radius: 2px;
                border: 1px solid rgb(102, 102, 102);
                padding: 10px;
                font-size: 14px;
                margin-bottom: 23px;
                width: calc(100% - 20px);
                max-width: 300px;
                displaY: block;
            }

            input[type="button"], input[type="submit"]{
                margin-bottom: 24px;
                min-height: 42px;
                cursor: pointer;
                outline: none;
                position: relative;
              color: white;
                background: black;
                border-radius: 27px;
                border: 0;
                padding: 12px 23px;
                font-size: 16px;
                text-align: center;
                text-decoration: none;
                display: inline-block;

               &:hover { background: black;}

            }
        }
    }

}

.dropShip {
    .summary {
        padding-top: 40px;
    }
}

// CHECKOUT PAGE (DROP SHIPPING)
.checkout {
    .checkoutGrid {

        form {
            background: #ffffff;
            width: calc(100% - 34px);
            padding: 0px 10px;
            position: relative;

            input:invalid {
              border: 2px dashed red;
            }

            input:invalid:required {
              background-image: linear-gradient(to right, pink, lightgreen);
            }

            input:valid {
              border: 2px solid black;
            }

            .shippingFormBodyRow {
                margin-top: 12px;
            }

            .react-select .invalid-field__control {
                border-color: red;

                &::placeholder {
                    color: red;
                }

                &::-webkit-input-placeholder {
                    color: red;
                }

                &::-moz-placeholder {
                    color: red;
                }

                &::-ms-input-placeholder {
                    color: red;
                }
            }

            input[type="text"],
            input[type="password"] {
                border-radius: 2px;
                border: 1px solid #555;
                padding: 10px;
                font-size: 14px;
                width: calc(100% - 20px);

                &::placeholder {
                    color: black;
                }

                &::-webkit-input-placeholder {
                    color: grey;
                }

                &::-moz-placeholder {
                    color: grey;
                }

                &::-ms-input-placeholder {
                    color: grey;
                }

                &.validated-field {
                    border: 1px solid transparent;
                }

                &.invalid-field {
                    border-color: red;

                    &::placeholder {
                        color: red;
                    }

                    &::-webkit-input-placeholder {
                        color: red;
                    }

                    &::-moz-placeholder {
                        color: red;
                    }

                    &::-ms-input-placeholder {
                        color: red;
                    }
                }
            }

            button {
                display: block;
                margin-bottom: 24px;
                min-height: 42px;
                width: 100%;
                cursor: pointer;
                outline: none;
                padding-left: 0;
                padding-right: 0;
                position: relative;

                &:disabled {
                    cursor: not-allowed;
                }
            }

            svg {
                display: inline;
                fill: white;
                height: 36px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 36px;
            }

                label {
                    line-height: 1.25em;
                    display: block;
                    margin-bottom: 5px;
                }
            .retry-message {
                left: 0;
                position: absolute;
                text-align: center;
                top: 15px;
                width: 100%;

            }
        }

    }
}
